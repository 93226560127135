var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"boton-flotante",attrs:{"color":"rgba(2, 130, 124, 1)","dark":"","absolute":"","fab":""},on:{"click":function($event){_vm.drawer = true}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('v-navigation-drawer',{attrs:{"color":"rgba(0, 143, 135, 141)","dark":"","absolute":"","temporary":"","width":"200"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"pt-5"},[_c('v-avatar',{staticClass:"centrar-imagen",attrs:{"size":"70","tile":""}},[_c('img',{attrs:{"src":require("../assets/admin/logo-te.png")}})])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("Teacher Edition")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-title',[_vm._v("Dashboard")])],1),(_vm.hasClassrooms)?_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher/classroom')}}},[_c('v-list-item-icon',[_c('img',{attrs:{"src":require("../assets/admin/clase.svg")}})]),_c('v-list-item-title',[_vm._v("Classroom")])],1):_c('v-tooltip',{attrs:{"color":"grey darken-3","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('img',{attrs:{"src":require("../assets/admin/clase.svg")}})]),_c('v-list-item-title',{staticClass:"teal--text text--lighten-3"},[_vm._v("Classroom")])],1)]}}])},[_c('span',[_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Classroom is only available with the Lee Lee Student Edition purchase.")]),_c('p',{staticClass:"my-0"},[_vm._v("Available in Classroom: ")]),_c('ul',[_c('li',[_vm._v("Game Progress")]),_c('li',[_vm._v("Multiplayer feature")]),_c('li',[_vm._v("Progress Checks")]),_c('li',[_vm._v("Lee Lee At-Home QR Codes")])])])]),_c('v-list-item',{on:{"click":_vm.OpenTe}},[_c('div',{staticClass:"icono-lateral"},[_c('img',{attrs:{"src":require("../assets/admin/teaching-tools.svg")}})]),_c('v-list-item-title',[_vm._v("Teaching Tools")])],1),(_vm.hasClassrooms)?_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher/resources')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-align-vertical-bottom")])],1),_c('v-list-item-title',[_vm._v("Reports")])],1):_c('v-tooltip',{attrs:{"color":"grey darken-3","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-align-vertical-bottom")])],1),_c('v-list-item-title',{staticClass:"teal--text text--lighten-3"},[_vm._v("Reports")])],1)]}}])},[_c('span',[_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Reports is only available with the Lee Lee Student Edition purchase.")]),_c('p',{staticClass:"my-0"},[_vm._v("Available in Reports:")]),_c('ul',[_c('li',[_vm._v("Tracking student learning and errors")]),_c('li',[_vm._v("Assesment results")])])])]),_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher/resources')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-cog")])],1),_c('v-list-item-title',[_vm._v("Resources")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher/settings')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',[_vm._v("Settings")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.goToLink('/schoolTeacher/help')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-help-circle")])],1),_c('v-list-item-title',[_vm._v("Help Center")])],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Logout")])])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","timeout":"4000","color":"blue-grey"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" se actualizo correctamente ")]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }