import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'


const firebaseConfig = {
  apiKey: "AIzaSyBKqvBPth6FkPFEvWfvMFaoMuU4hKUibJw",
  authDomain: "leeleeadmin2022.firebaseapp.com",
  projectId: "leeleeadmin2022",
  storageBucket: "leeleeadmin2022.appspot.com",
  messagingSenderId: "164645668706",
  appId: "1:164645668706:web:f315133ffd814357218e3b"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyC7ryWeclM09aIsFP51onIQuJbA8Ik_03Y",
//   authDomain: "backup2022-3cc18.firebaseapp.com",
//   projectId: "backup2022-3cc18",
//   storageBucket: "backup2022-3cc18.appspot.com",
//   messagingSenderId: "274489431121",
//   appId: "1:274489431121:web:b62eeac58185b4f7111470"
// };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }
  
  const db = firebase.firestore();
  const functions = firebase.functions();
  const auth = firebase.auth;
  // const settings = { host: "localhost:8080", ssl: false, timestampsInSnapshots: true };
  // try { db.settings(settings); } catch (e) { console.log(e) }
  // // db.useEmulator("localhost", 8080);
  // auth().useEmulator("http://localhost:9099/", { disableWarnings: true });
  // firebase.functions().useEmulator("localhost", 5001);
  export { db, auth, functions };