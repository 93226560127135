<template>
  <div>
    <v-btn color="rgba(2, 130, 124, 1)" class="boton-flotante" dark absolute  fab @click="drawer = true" >
        <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      color="rgba(0, 143, 135, 141)"
      dark
      absolute
      temporary
      width="200"
    >
      <v-list-item class="pt-5">
        <v-avatar size="70" tile class="centrar-imagen">
          <img src="../assets/admin/logo-te.png">
        </v-avatar>
        <!-- <v-list-item-avatar size="150">
         
        </v-list-item-avatar> -->
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Teacher Edition</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <!--<v-list-item href="/schoolTeacher" >-->
          <v-list-item @click="goToLink('/schoolTeacher')"> 
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <!--<v-list-item href="/schoolTeacher/classroom" v-if="IsRegister !='true'">
          <v-list-item @click="goToLink('/schoolTeacher/classroom')"  v-if="hasClassrooms && IsRegister !='true'">-->
          <v-list-item @click="goToLink('/schoolTeacher/classroom')"  v-if="hasClassrooms"> 
            <v-list-item-icon>
              <img src="../assets/admin/clase.svg">
            </v-list-item-icon>
            <v-list-item-title>Classroom</v-list-item-title>
          </v-list-item>
          <v-tooltip color="grey darken-3" right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <img src="../assets/admin/clase.svg">
            </v-list-item-icon>
            <v-list-item-title class="teal--text text--lighten-3">Classroom</v-list-item-title>
        </v-list-item>
          </template>
          <span>
            <p class="mb-0 mt-2">Classroom is only available with the Lee Lee Student Edition purchase.</p>
            <p class="my-0">Available in Classroom: </p>
            <ul>
              <li>Game Progress</li>
              <li>Multiplayer feature</li>
              <li>Progress Checks</li>
              <li>Lee Lee At-Home QR Codes</li>
            </ul>
          </span>
        </v-tooltip>
          <v-list-item @click="OpenTe">
            <div class="icono-lateral">
              <img src="../assets/admin/teaching-tools.svg">
            </div>
            <v-list-item-title>Teaching Tools</v-list-item-title>
          </v-list-item>
          <!--<v-list-item href="/schoolTeacher/statistics" v-if="IsRegister !='true'">
          <v-list-item @click="goToLink('/schoolTeacher/resources')" v-if="hasClassrooms && IsRegister !='true'">--> 
          <v-list-item @click="goToLink('/schoolTeacher/resources')" v-if="hasClassrooms"> 
            <v-list-item-icon>
              <v-icon>mdi-align-vertical-bottom</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item>
          <v-tooltip color="grey darken-3" right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-align-vertical-bottom</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="teal--text text--lighten-3">Reports</v-list-item-title>
            </v-list-item>
          </template>
          <span>
            <p class="mb-0 mt-2">Reports is only available with the Lee Lee Student Edition purchase.</p>
            <p class="my-0">Available in Reports:</p>
            <ul>
              <li>Tracking student learning and errors</li>
              <li>Assesment results</li>
            </ul>
          </span>
        </v-tooltip>
          <!--<v-list-item to="/schoolTeacher/resources">-->
          <v-list-item @click="goToLink('/schoolTeacher/resources')"> 
            <v-list-item-icon>
              <v-icon>mdi-file-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <!--<v-list-item href="/schoolTeacher/settings">-->
        <v-list-item @click="goToLink('/schoolTeacher/settings')"> 
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <!--<v-list-item href="/schoolTeacher/help">-->
        <v-list-item @click="goToLink('/schoolTeacher/help')">  
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help Center</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-dialog v-model="dialogSettings" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Set up your account
        </v-card-title>
        <v-row class="ma-5">
          <v-col class="ma-4">
            Change your password
          </v-col>
          <v-col>
            <v-text-field outlined label="Password" prepend-icon="mdi-lock" v-model="password" autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <v-btn class="ma-2" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-alert outlined type="warning" prominent border="left" class="ma-4" v-if="textUpdatePassword != ''">
           {{ textUpdatePassword }}
        </v-alert>
      </v-card>
    </v-dialog> -->
    <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
      se actualizo correctamente
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view/>
  </div>
</template>
<script>
import { auth, db } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',
    ButtonBakc: false,
    IsRegister : false,
    timeout: '', 
    idle: false,
    timeBeforeIdle: null,
    isChange: false,
    hasClassrooms: false,
    teacherExists: true,
    ftime: false
  }),
  methods: {
    async logout () {
      this.isChange = true;
      await auth().signOut();
      this.saveTimeOnline(); 
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      localStorage.removeItem('register');      
      setTimeout(function(){
        location.href = "/login";
      }, 1000); 
    },
    settings () {
      this.dialogSettings = true;
    },
    OpenTe () {
      if (this.$route.name != "activitiesList") {
        location.href = "/schoolTeacher/activitieslist";
      }else{
        this.drawer =false;
      }
    },
    async goToLink(link){
      this.isChange = true;
      this.saveTimeOnline();
      setTimeout(function(){
        location.href = link;
      }, 1000);
    },
    handleBeforeUnload(event) {
      if (!this.isChange) {
        // Personaliza el mensaje de advertencia que se mostrará al usuario
        const confirmationMessage = '¿Seguro que quieres abandonar la página? Los cambios no guardados se perderán.';
      
        // Establece el mensaje en el evento
        event.returnValue = confirmationMessage;
        
        // Algunos navegadores pueden no mostrar el mensaje personalizado
        return confirmationMessage;
      }
    },  
    async saveTimeOnline(){console.log("Desde saveTimeOnline: "+this.timeBeforeIdle);
      clearTimeout(this.timeout);

      const uidTeacher=this.$store.state.user.uid;
      //const teacherData=await db.collection("SchoolTeacher").doc(uidTeacher).get();
      //if (!teacherData.exists) {
      if (!this.teacherExists) {
        localStorage.removeItem('initialTime');
        localStorage.removeItem('savedTime');
        localStorage.removeItem('activeWeb');
        return;
      }

      const initialTime=localStorage.getItem('initialTime');
      let endTime = Date.now();
      if (this.timeBeforeIdle!=null && (endTime-this.timeBeforeIdle)>300000)
        endTime = this.timeBeforeIdle;

      // console.log("GUARDANDO TIME...");
      // console.log("initialTime-> "+initialTime, "endTime-> "+endTime);

      const onlineTime = Math.floor((endTime - initialTime) / 1000);
      // console.log("onlineTime-> "+onlineTime);
      // console.log("this.$store.state.user.uid-> "+uidTeacher);
      const savedTime=parseInt(localStorage.getItem('savedTime'));
      //db.collection("SchoolTeacher").doc(this.$store.state.user.uid).update({OnlineTimeWeb: onlineTime});
      try {
        //await db.collection("SchoolTeacher").doc(uidTeacher).update({OnlineTimeWeb: onlineTime});
        //await db.collection("SchoolTeacher").doc(uidTeacher).set({OnlineTimeWeb: onlineTime}, {merge: true});
        const xmlhttp=new XMLHttpRequest();
        xmlhttp.open("POST","https://us-central1-leeleeadmin2022.cloudfunctions.net/sendTeacherTimeWeb", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xmlhttp.send('onlineTime='+(onlineTime+savedTime)+'&id='+uidTeacher);
        this.$store.commit('setActiveWeb', false);       
        this.timeBeforeIdle = null; 
      } catch (error) {
        console.error("Error al guardar el tiempo en línea:", error);
      }
    },
    idleTimeExit(){
      let me=this;     
      this.timeout = setTimeout(function(){
        // console.log("se acabo el tiempo de espera, procedemos a guardar el tiempo en web.");
        me.saveTimeOnline();
        me.idle=true;
      },300000);//despues de 5 min guardamos los datos
    },
    async getDataTeacher(item){
      const teacherData=await db.collection("SchoolTeacher").doc(this.$store.state.user.uid).get();
      if (Object.prototype.hasOwnProperty.call(teacherData.data(), item)){
        return teacherData.data()[item];
      }else
        return undefined;
    },
    async getPlusTime(){
      await db.collection("SchoolTeacher").doc(this.$store.state.user.uid).onSnapshot((teacherData) => {
        if (teacherData.exists) {
          let timeS=0;
          if (Object.prototype.hasOwnProperty.call(teacherData.data(), 'OnlineTimeWeb')){
            if (!isNaN(teacherData.data().OnlineTimeWeb) && teacherData.data().OnlineTimeWeb>0){
              timeS=teacherData.data().OnlineTimeWeb;
            }
          }
          if (this.IsRegister)
            this.hasClassrooms=Object.prototype.hasOwnProperty.call(teacherData.data(), 'HasClassrooms')?teacherData.data().HasClassrooms:false;
          else
            this.hasClassrooms=Object.prototype.hasOwnProperty.call(teacherData.data(), 'HasClassrooms')?teacherData.data().HasClassrooms:true;
          if (!this.ftime)
            this.$store.commit('setSavedTime', timeS);

          this.ftime=false;
        }else{
          this.teacherExists=false;
          // console.log('El profesor ha sido eliminado.');
        }  
      }, (error) => {
        console.log(error);
      });
    },
    async startTimer(){      console.log("Desde startTImer: "+this.timeBeforeIdle);
      const activeW=localStorage.getItem('activeWeb');      
      console.log("Estadooo-->>",activeW);
      if (activeW==null || activeW=='false'){
        console.log("***********************VOlVEMOS a COGER EL TIEMPO**********************");
        await this.getPlusTime();
        
        const initialTime = Date.now();
        this.$store.commit('setInitialTime', initialTime);
        this.$store.commit('setActiveWeb', true);      
      }
    },
    browserStatus(){
      //const endTime = Date.now();
      let myDate=new Date();
      let hours = myDate.getHours();
      let minutes = myDate.getMinutes();
      let seconds = myDate.getSeconds();
      if (hours < 10) hours = 0 + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;
      // console.log(document.visibilityState);
      // console.log("No me ves desde: "+hours+ ":" +minutes+ ":" +seconds);
      if (document.visibilityState=='visible')
        this.timeBeforeIdle = null;
    },
    timeWithoutTouching(){
      this.timeBeforeIdle = Date.now();
      // console.log("Time Before Idle: "+this.timeBeforeIdle);      
    },
    touching(){
      this.timeBeforeIdle = null;
      // console.log("TENEMOS EL FOCO OTRA VEZ");
    },
    mouseMove() {
      if (!this.idle){
        //if (!me.enableMove) return;
        clearTimeout(this.timeout);
        // console.log("mouse move");
        this.idleTimeExit();
      }else{//Si la web estuvo inactiva, volvemos a contar el tiempo
        this.startTimer();
        this.idle=!this.idle;
      }
    }
  },
  created() {
    window.addEventListener('mouseout', this.timeWithoutTouching);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('beforeunload', this.saveTimeOnline);
    window.addEventListener('blur', this.timeWithoutTouching);
    window.addEventListener('focus', this.touching);
    window.addEventListener('visibilitychange', this.browserStatus);
  },
  async mounted() {
    if (this.$store.state.user.role != "schoolTeacher") {
      this.logout();
    }

    this.hasClassrooms=await this.getDataTeacher('HasClassrooms');
    this.hasClassrooms=(this.hasClassrooms==undefined?true:this.hasClassrooms);
    this.IsRegister = localStorage.getItem('register');
    this.ftime=true; 
    this.getPlusTime()
    //this.startTimer();
    this.idleTimeExit();

    let me = this;     
    document.onmousemove = me.mouseMove;
  },
  beforeDestroy() {    
    clearTimeout(this.timeout);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);    
  }
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
  .boton-flotante {
    width: 45px !important;
    height: 45px !important;
  }
  @media only screen and (max-width: 1200px) {
    .boton-flotante {
      width: 40px !important;
      height: 40px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .boton-flotante {
      width: 35px !important;
      height: 35px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .boton-flotante {
      width: 30px !important;
      height: 30px !important;
    }
  }
</style>